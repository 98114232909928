import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import { getStorage, ref } from "firebase/storage";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    apiKey: "AIzaSyBWutEHbnsZItjMYoh8xugZDU9jqhd5iHg",
    authDomain: "byarnold-5bd21.firebaseapp.com",
    databaseURL: "https://byarnold-5bd21-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "byarnold-5bd21",
    storageBucket: "byarnold-5bd21.appspot.com",
    messagingSenderId: "866800828258",
    appId: "1:866800828258:web:bb28d4e32fa51d810d363b"
  };

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage();